import { cn } from "@/utils/cn";
import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";
import LoadingIcon from "../Icon/icons/loading";
import OreaArrowBlueIcon from "../Icon/orea-custom/orea-arrow-blue";
import { Skeleton } from "../Skeleton";

const variants = cva(
  "relative inline-flex font-semibold items-center justify-center border border-border-light rounded-2xl text-base disabled:pointer-events-none disabled:opacity-60 w-full focus:outline-none min-h-16 box-border transition-[box-shadow,background-color,color]",
  {
    variants: {
      variant: {
        default:
          "[&_.box-icon]:text-black text-button-secondary-text focus:ring-1 focus:ring-button-secondary-focus-ring focus:border-border-muted hover:border-border-muted active:border-border-muted",
        dark: "[&_.box-icon]:text-white group/box-dark bg-button-primary text-button-primary-text focus:ring-4 focus:ring-button-primary-focus-ring focus:bg-button-primary-focus  hover:bg-button-primary-hover active:bg-button-primary-active active:ring-2 active:ring-button-primary-active-ring",
      },
      size: {
        default: "py-3 px-4",
        defaultEven: "p-3",
        "without-padding": "p-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface AdvancedBoxActionShellProps {
  icon?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  className?: string;
  actionIcon?: ReactNode;
}

const AdvancedBoxActionShell = ({ icon, title, subtitle, children, className, actionIcon }: AdvancedBoxActionShellProps) => (
  <div className={cn("flex w-full items-center justify-between gap-2", className)}>
    {children || (
      <div className="flex items-center gap-4">
        <span className="[&>*]:h-6 [&>*]:w-6">{icon}</span>
        <div className="flex flex-col gap-1 text-left">
          <div className="font-primary leading-none capsize">{title}</div>
          {subtitle && <div className="text-primary-light text-sm">{subtitle}</div>}
        </div>
      </div>
    )}
    {actionIcon || <OreaArrowBlueIcon className="flex-shrink-0" />}
  </div>
);

const AdvancedBoxActionShellSkeleton = () => (
  <div className="flex w-full items-center justify-between gap-2">
    <div className="flex items-center gap-4">
      <span className="h-6 w-6">
        <Skeleton className="h-6 w-6" />
      </span>
      <div className="flex flex-col gap-1 text-left">
        <div>
          <Skeleton className="h-4 w-16" />
        </div>
        <div className="text-primary-light text-sm">
          <Skeleton className="h-4 w-16" />
        </div>
      </div>
    </div>
    <Skeleton className="h-6 w-6" />
  </div>
);

export interface AdvancedBoxProps extends VariantProps<typeof variants> {
  children?: ReactNode;
  isLoading?: boolean;
  className?: string;
}

const AdvancedBoxBase = ({ children, isLoading, className }: AdvancedBoxProps) => (
  <>
    {isLoading && (
      <span className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
        <LoadingIcon className="h-5 w-5 animate-spin" />
      </span>
    )}

    <div
      className={cn(
        "inline-flex w-full items-center justify-center",
        {
          "opacity-40 blur-[0.75px]": isLoading,
        },
        className,
      )}
    >
      {children}
    </div>
  </>
);

export { AdvancedBoxActionShell, AdvancedBoxActionShellSkeleton, AdvancedBoxBase, variants as advancedBoxVariants };
