import { I18N_NAMESPACES } from "@/constants/i18n";
import { ROUTES } from "@/constants/routes";
import { Hotel } from "@/data-access/gqlTypes";
import { useTranslation } from "next-i18next";
import { AspectRatio } from "./AspectRatio";
import OreaLocationIcon from "./Icon/orea-custom/orea-location";
import Link from "./Link";
import OptimizedImage from "./OptimizedImage";
import RatingStars from "./RatingStars";

interface Props {
  hotel: Pick<Hotel, "name" | "slug" | "hotelPhotoUrls" | "city" | "rating">;
}

const HotelCard = ({ hotel }: Props) => {
  const { t } = useTranslation(I18N_NAMESPACES.COMMON);
  const { name, slug, hotelPhotoUrls, city, rating } = hotel;

  const hotelPhotoUrl = hotelPhotoUrls?.[0]?.url || "";
  const alt = hotel.hotelPhotoUrls?.[0]?.alternativeText || t("alt.hotelImage");

  const link = slug?.length ? `${ROUTES.HOTELS}/${slug}` : ROUTES.HOTELS;

  return (
    <Link href={link} className="flex max-h-64 gap-3 overflow-hidden rounded-2xl p-3 shadow-button transition-shadow hover:shadow-button-hover">
      <div className="w-32 flex-shrink-0">
        <AspectRatio ratio={1 / 1}>
          <OptimizedImage src={hotelPhotoUrl ?? ""} className="rounded-xl object-cover" priority alt={alt} fill sizes="256px" />
        </AspectRatio>
      </div>
      <div className="space-y-2 py-2">
        <h2 className="h5 break-words font-bold text-black">{name}</h2>
        <div className="flex flex-col flex-wrap gap-2 md:flex-row md:items-center md:gap-6">
          <RatingStars className="self-start md:self-center" rating={Number(rating)} />
          {!!city?.length && (
            <div className="flex items-center gap-x-2">
              <OreaLocationIcon className="text-primary" />
              <span className="text-primary-dark">{city}</span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default HotelCard;
