import { cn } from "@/utils/cn";
import { StarIcon } from "lucide-react";

interface Props {
  rating: number;
  className?: string;
}

const RatingStars = ({ rating, className }: Props) => (
  <div className={cn("flex gap-x-1 self-center", className)}>
    {Array.from({ length: rating }, (_, i) => (
      <StarIcon key={i} className="size-6 fill-yellow text-yellow" />
    ))}
  </div>
);

export default RatingStars;
