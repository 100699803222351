import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/utils/cn";
import { ReactNode, useState } from "react";
import { Dialog, DialogContent, DialogTrigger } from "./Dialog";
import { Drawer, DrawerContent, DrawerTrigger } from "./Drawer";
import { ScrollArea } from "./ScrollArea";

interface Props {
  children?: ReactNode;
  trigger: ReactNode;
  isLoading?: boolean;
  dialogClassName?: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

const ResponsiveDrawerModal = ({ children, trigger, isLoading, dialogClassName, open: propsOpen, setOpen: propsSetOpen }: Props) => {
  const [internalOpen, setInternalOpen] = useState(false);
  const open = propsOpen ?? internalOpen;
  const setOpen = propsSetOpen ?? setInternalOpen;

  const isDesktop = useMediaQuery("md");
  if (isDesktop) {
    return (
      <Dialog open={!isLoading && open} onOpenChange={setOpen}>
        <DialogTrigger asChild>{trigger}</DialogTrigger>
        <DialogContent
          className={cn("flex max-h-[calc(100dvh-2rem)] overflow-hidden p-0 md:max-h-[70%] md:max-w-screen-sm lg:max-w-3xl", dialogClassName)}
        >
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={!isLoading && open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent className="h-fit max-h-[calc(100dvh-2rem)]">
        <ScrollArea className="overflow-auto overscroll-contain p-6 pb-2 pt-2">{children}</ScrollArea>
      </DrawerContent>
    </Drawer>
  );
};

export default ResponsiveDrawerModal;
